<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CCardTitle>CloudRouter ☁️</CCardTitle>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav" v-if="!$cookies.get('is_admin')"/>
    <CRenderFunction flat :content-to-render="navAdminShow" v-if="$cookies.get('is_admin')"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navAdmin from './_nav_admin'

export default {
  name: 'TheSidebar',
  nav,
  navAdmin,
  data () {
    return {
      navAdminShow:[],
    }
  },
  mounted: function () {
    this.navAdminShow = navAdmin
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
