export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Index",
        to: "/index",
        icon: "cil-speedometer"
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Router"]
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Router",
        route: "/router",
        icon: "cil-router",
        items: [
          {
            name: "List",
            to: "/router"
          },
          {
            name: "Add",
            to: "/router/add"
          },
          {
            name: "Share Router",
            to: "/router/auth"
          },
        ]
      },
      {
        _name: "CSidebarNavItem",
        name: "Port Forward",
        to: "/port-forward",
        icon: "cil-flight-takeoff"
      },
      {
        _name: "CSidebarNavDropdown",
        name: "CMDB",
        route: "/cmdb",
        icon: "cil-contact",
        items: [
          {
            name: "Device List",
            to: "/cmdb/device"
          },
          {
            name: "Add CMDB Device",
            to: "/cmdb/device/add"
          },
          {
            name: "DDNS List",
            to: "/cmdb/ddns"
          },
          {
            name: "Add DDNS Instance",
            to: "/cmdb/ddns/add"
          },
        ]
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Account"]
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Setting",
        route: "/user",
        icon: "cil-user",
        items: [
          {
            name: "Info",
            to: "/user/info"
          }
        ]
      },
    ]
  }
];